<template>
  <div class="basko-box-confirm-consent-container">
    <CategoryTitle :category="categoryConsent" />

    <v-form
      class="mt-8"
      ref="formRef"
      v-model="valid"
      lazy-validation
      @submit.prevent.stop="handleSubmit"
    >
      <v-text-field
        color="primary"
        v-model="note1"
        type="email"
        :label="$t('eugenio.consents.additionalNote1')"
        class="mb-2"
        dense
        outlined
        clearable
      ></v-text-field>
      <v-text-field
        color="primary"
        v-model="note2"
        type="email"
        :label="$t('eugenio.consents.additionalNote2')"
        class="mb-2"
        dense
        outlined
        clearable
      ></v-text-field>

      <a
        class="d-block"
        :href="$t('eugenio.consents.productPriceLink')"
        target="_black"
      >
        {{ $t("eugenio.consents.productPriceBtn") }}
      </a>
      <a
        class="d-block mt-3"
        :href="$t('eugenio.consents.generalClauseLink')"
        target="_black"
      >
        {{ $t("eugenio.consents.generalClauseBtn") }}
      </a>

      <v-divider class="my-5" />
      <span class="text-h3">
        {{ $t("eugenio.consents.confirmAgreementTitle") }}
      </span>

      <span
        class="d-block mt-3"
        v-html="$t('eugenio.consents.confirmAgreementLabel')"
      ></span>
      <v-checkbox
        v-model="agreementCheckbox"
        required
        :rules="checkboxRules"
        false-value="false"
        true-value="true"
        :label="$t('eugenio.consents.confirmCheckbox')"
      ></v-checkbox>

      <div class="d-flex justify-end">
        <v-btn color="primary" depressed large type="submit">
          {{ $t("eugenio.consents.goToNextStepBtn") }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>
<style lang="scss">
.basko-box-confirm-consent-container {
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";

import recurrentOrderService from "@/commons/service/recurrentOrderService";

import { requiredValue, isCheckboxTrue } from "~/validator/validationRules";

export default {
  name: "BaskoBoxConfirmConsent",
  components: {
    CategoryTitle
  },
  props: {
    addressId: { type: String, required: true },
    timeslotId: { type: String, required: true },
    weekDay: { type: String, required: true }
  },
  data() {
    return {
      loading: true,
      valid: true,
      note1: "",
      note2: "",
      agreementCheckbox: false,
      checkboxRules: [
        requiredValue("È obbligatorio accettare questo consenso per procedere"),
        isCheckboxTrue("È obbligatorio accettare questo consenso per procedere")
      ],

      categoryConsent: {
        categoryId: 363541,
        name: "Conferma i tuoi consensi",
        slug: "basko-box",
        categoryTypeId: "999",
        description: "",
        metaData: {
          category_info: {
            HEADER_ICON: "",
            TITLE: "Conferma i tuoi consensi"
          }
        }
      }
    };
  },
  computed: {
    consent() {
      return "Davanti alla porta;" + this.note1 + ";" + this.note2;
    }
  },
  methods: {
    async handleSubmit() {
      try {
        if (this.agreementCheckbox) {
          this.loading = true;
          if (this.$refs.formRef.validate()) {
            let res = await recurrentOrderService.subscribe(
              this.timeslotId,
              this.addressId,
              this.weekDay,
              this.consent
            );
            if (res?.response.status === 0) {
              this.$router.push({
                name: "BaskoBoxQuestions",
                query: { isRegistration: true }
              });
            }
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  },
  async mounted() {}
};
</script>
